<template>
  <b-modal
    id="clipper"
    ref="clipper-modal"
    centered
    hide-footer
    hide-header
    size="md"
  >
    <input
      id="image"
      style="display: none"
      type="file"
      @change="onFileChange"
    />
    <h3 class="text-center font-weight-semi-bold text-color-1 mb-9">
      {{
        mode !== "fixed"
          ? $t("setting.clipperProfileImageUpload")
          : $t("setting.clipperImageUpload")
      }}
    </h3>
    <div class="bg-white px-5 px-xl-6 text-center">
      <span
        v-if="!isMentorcast"
        class="browse-button-design"
        @click="showImageUpload"
      >
        {{ $t("profile.clipermodalBrowse") }}
        <inline-svg
          :src="require(`@/assets/icons/camera.svg`)"
          height="20"
          width="20"
        ></inline-svg>
      </span>
      <cropper
        v-if="mode === 'fixed'"
        :key="cropperKey"
        ref="cropper"
        class="cropper"
        :src="src"
        :stencil-props="{
          handlers: {},
          movable: false,
          scalable: false,
          maxAspectRatio: 16 / 9
        }"
        :stencil-size="{
          width: stencilWidth,
          height: stencilHeight
        }"
        :resize-image="{
          adjustStencil: false
        }"
        image-restriction="stencil"
      />
      <cropper
        v-else-if="mode === 'circle'"
        :key="cropperKey"
        ref="cropper"
        :src="src"
        :stencil-props="{
          previewClass: 'circle-cropper__preview'
        }"
        class="circle-cropper"
        stencil-component="circle-stencil"
      />
    </div>
    <b-row align-h="center" class="mt-3" no-gutters>
      <b-col class="d-flex justify-content-center px-3 mt-8" cols="auto">
        <b-button
          :disabled="!src"
          class="mx-2 mx-sm-0 main-btn"
          variant="primary"
          @click="save"
        >
          <b-spinner v-if="btnLoading" class="mr-2" small></b-spinner>
          {{ btnName }}
        </b-button>
      </b-col>
      <b-col class="d-flex justify-content-center px-3 mt-8" cols="auto">
        <b-button
          class="text-color-2 mx-2 mx-sm-0 main-btn"
          variant="outline-dark"
          @click="() => $refs['clipper-modal'].hide()"
          >{{ $t("profile.clipermodalLater") }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ClipperModal",
  components: {
    Cropper
  },
  props: {
    image: String,
    btnName: String,
    mode: {
      type: String,
      default: "fixed"
    },
    isMentorcast: Boolean,
    stencilHeight: {
      type: Number,
      default: 240
    },
    stencilWidth: {
      type: Number,
      default: 426
    }
  },
  data() {
    return {
      src: this.image,
      cropperKey: 0,
      btnLoading: false
    };
  },
  watch: {
    image(newVal) {
      this.src = newVal;
      this.cropperKey++;
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.src = URL.createObjectURL(file);
    },
    showImageUpload() {
      // @ts-ignore
      document.getElementById("image").click();
    },
    save() {
      this.btnLoading = true;

      const { canvas } = this.$refs.cropper.getResult();

      const resultURL = canvas.toDataURL("image/jpeg", 1);

      this.$store
        .dispatch("helper/STORE_TEMP_IMAGE", resultURL)
        .then(data => {
          this.btnLoading = false;
          this.$emit("file", data);
          this.$bvModal.hide("clipper");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    }
  }
};
</script>
<style lang="scss">
.circle-cropper {
  width: 100%;
  background: #222;

  &__preview {
    border: solid 1px rgba(white, 0.15);
  }
}
</style>

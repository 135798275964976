var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"clipper-modal",attrs:{"id":"clipper","centered":"","hide-footer":"","hide-header":"","size":"md"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"id":"image","type":"file"},on:{"change":_vm.onFileChange}}),_c('h3',{staticClass:"text-center font-weight-semi-bold text-color-1 mb-9"},[_vm._v(" "+_vm._s(_vm.mode !== "fixed" ? _vm.$t("setting.clipperProfileImageUpload") : _vm.$t("setting.clipperImageUpload"))+" ")]),_c('div',{staticClass:"bg-white px-5 px-xl-6 text-center"},[(!_vm.isMentorcast)?_c('span',{staticClass:"browse-button-design",on:{"click":_vm.showImageUpload}},[_vm._v(" "+_vm._s(_vm.$t("profile.clipermodalBrowse"))+" "),_c('inline-svg',{attrs:{"src":require(`@/assets/icons/camera.svg`),"height":"20","width":"20"}})],1):_vm._e(),(_vm.mode === 'fixed')?_c('cropper',{key:_vm.cropperKey,ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.src,"stencil-props":{
        handlers: {},
        movable: false,
        scalable: false,
        maxAspectRatio: 16 / 9
      },"stencil-size":{
        width: _vm.stencilWidth,
        height: _vm.stencilHeight
      },"resize-image":{
        adjustStencil: false
      },"image-restriction":"stencil"}}):(_vm.mode === 'circle')?_c('cropper',{key:_vm.cropperKey,ref:"cropper",staticClass:"circle-cropper",attrs:{"src":_vm.src,"stencil-props":{
        previewClass: 'circle-cropper__preview'
      },"stencil-component":"circle-stencil"}}):_vm._e()],1),_c('b-row',{staticClass:"mt-3",attrs:{"align-h":"center","no-gutters":""}},[_c('b-col',{staticClass:"d-flex justify-content-center px-3 mt-8",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mx-2 mx-sm-0 main-btn",attrs:{"disabled":!_vm.src,"variant":"primary"},on:{"click":_vm.save}},[(_vm.btnLoading)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.btnName)+" ")],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center px-3 mt-8",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"text-color-2 mx-2 mx-sm-0 main-btn",attrs:{"variant":"outline-dark"},on:{"click":() => _vm.$refs['clipper-modal'].hide()}},[_vm._v(_vm._s(_vm.$t("profile.clipermodalLater"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }